<template>
  <div class="admin-detail">
    <div class="admin-navbar justify-between">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
      <div class="detail-buttons">
        <button class="detail-button"
                @click="$router.push(`/admin/faq/${$route.params.id}/edit`)">
          {{ $lang.app.edit }}</button>
      </div>
    </div>
    <div v-if="faqItem" class="admin-info">
      <h4 class="info-title">{{ $lang.app.faq }}</h4>
      <div class="info-fields">
        <div class="info-field">
          <p class="field-label">{{ $lang.app.category }}:</p>
          <p class="field-value">{{ typeLabel(faqItem.type) }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.question }} ({{ $lang.app.ru }}):</p>
          <p class="field-value">{{ faqItem.titleRu }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.question }} ({{ $lang.app.kg }}):</p>
          <p class="field-value">{{ faqItem.titleKg }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.answer }} ({{ $lang.app.ru }}):</p>
          <p class="field-value" v-html="faqItem.textRu"></p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.answer }} ({{ $lang.app.kg }}):</p>
          <p class="field-value" v-html="faqItem.textKg"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqItem: null,
    }
  },
  methods: {
    typeLabel(value) {
      switch (value) {
        case 'SCHOOL': return this.$lang.app.school
        case 'PRESCHOOL': return this.$lang.app.preschool
      }
    },
    fetchResource() {
      this.$axios.get(`/qna/${this.$route.params.id}`).then(({data}) => {
        this.faqItem = data
      })
    }
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>